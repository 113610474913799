import Vue from "vue";
// import {Buefy} from 'buefy';
import {
  Input,
  Message,
  Tag,
  Notification,
  Navbar,
  Pagination,
  Button,
  Field,
  Skeleton,
  Image,
} from "buefy";
import "buefy/dist/buefy.css";

// 使用完整包
// Vue.use(Buefy);

// 按需加载
Vue.use(Input);
Vue.use(Message);
Vue.use(Tag);
Vue.use(Notification);
Vue.use(Navbar);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Field);
Vue.use(Skeleton);
Vue.use(Image);
