import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomeView,
    redirect: "home",
    children: [
      {
        path: "/home/:id(\\d+)?",
        name: "home",
        component: () => import("@/components/HomeListComp.vue"),
      },
      {
        path: "/detail/:id",
        name: "detail",
        component: () => import("@/components/DetailComp.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
