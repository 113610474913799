<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">博主简介</p>
    </div>
    <div class="card-content">
      <div class="avatar block">
        <b-image :src="avatar" ratio="1by1" rounded v-if="avatar"></b-image>
        <b-skeleton :active="!avatar"></b-skeleton>
      </div>

      <div class="block">
        <b-notification type="is-light" :closable="false">
          <a href="https://github.com/qidadaaa" target="_blank"> 祁大大 </a>:
          {{ introduction }}
        </b-notification>
      </div>
    </div>
  </div>
</template>

<script>
import { sayHi } from "@/api/user";

export default {
  data() {
    return {
      avatar: "",
      introduction: "",
      successLoad: false,
    };
  },
  mounted() {
    sayHi().then((res) => {
      if (res.code != 200) return;
      this.introduction = res.data.introduction;

      const img = new Image();
      img.onload = this.avatarOnload;
      img.onerror = this.avatarError;
      img.src = res.data.avatar;
    });
  },
  methods: {
    avatarOnload(event) {
      this.avatar = event.target.src;
      this.successLoad = true;
    },
    avatarError() {
      this.avatar = require("@/assets/default.jpg");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}
</style>>