<template>
  <footer>
    <a href="https://beian.miit.gov.cn/" target="__blank">
      粤ICP备2023062370号-1
    </a>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: center;
}
</style>
