export default class ParticleBoom {
  #el;
  #parent;
  #timer;
  #raf;

  constructor() {
    this.#el = document.createElement("img");
    this.#el.setAttribute("src", require("@/assets/snow.png"));
    const width = Math.trunc(Math.random() * 20);
    this.#el.setAttribute(
      "style",
      `opacity: 1; 
      width: ${width}px;
      height: ${width}px;
      position: fixed;
      left: -200%;
      z-index: 1;
      transition: transform 0.1s ease, opacity 0.1s ease;
      `
    );
  }

  set parent(element) {
    element.appendChild(this.#el);
    this.#parent = element;
  }

  run(position) {
    this.#el.style.top = position.y + "px";
    this.#el.style.left = position.x + "px";

    const randomX = Math.random() * 100 - 50;
    const randomY = Math.random() * 100 - 50;

    if (this.#raf) {
      cancelAnimationFrame(this.#raf);
    }
    this.#raf = requestAnimationFrame(() => {
      this.#el.style.transform = `translate(${randomX}px, ${randomY}px)`;
      this.#el.style.opacity = 0;
    });

    if (this.#timer) {
      clearTimeout(this.#timer);
      this.#timer = null;
    }
    this.#timer = setTimeout(() => {
      this.#timer = null;
      this.clear();
    }, 100);
  }

  clear() {
    this.#el.style.transform = "translate(0)";
    this.#el.style.opacity = 1;
    this.#el.style.left = "-200%";
  }
}
