import axios from "axios";
import router from "@/router";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || "/api",
  timeout: 30 * 1000,
});

request.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log("request error - ", error);

    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    if (!response.data) {
      console.error(response.data);
    }
    return response.data || {};
  },
  function (error) {
    if (process.env.NODE_ENV === "development") {
      console.error(
        `${error.message} : ${error.config.baseURL}${error.config.url}`
      );
    }

    // 非首页, 则重定向
    if (router?.history?.current?.path.search(/^\/|\/home$/) == -1) {
      router.replace("/");
    }
    return error;
  }
);

export default request;
