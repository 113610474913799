import request from "@/utils/request";

export function getTags() {
  return request({
    url: "/tags",
    method: "get",
  });
}

export function getHotTags() {
  return request({
    url: "/tags/hot",
    method: "get",
  });
}
