<template>
  <div class="wrapper common-main_not-select">
    <SnowComp></SnowComp>
    <ParticleComp></ParticleComp>
    <div class="wrapper-item-main">
      <div class="columns is-centered">
        <div class="column is-9">
          <NavComp></NavComp>

          <div class="columns">
            <div class="column is-9">
              <router-view></router-view>
            </div>
            <div class="column is-3 common-main_not-select">
              <!-- 个人简介 -->
              <div class="block">
                <MyIntoductionComp></MyIntoductionComp>
              </div>

              <!-- 热门标签 -->
              <div class="block">
                <HotTagComp></HotTagComp>
              </div>

              <!-- 回到顶部 -->
              <div class="block">
                <transition name="fade">
                  <b-button
                    class="backToTopBtn"
                    @click="scrollTop"
                    v-show="isShowBackTopBtn"
                    type="is-primary"
                  >
                    ▲
                  </b-button>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComp></FooterComp>
  </div>
</template>

<script>
import NavComp from "@/components/NavComp.vue";
import MyIntoductionComp from "@/components/MyIntoductionComp";
import HotTagComp from "@/components/HotTagComp.vue";
import SnowComp from "@/components/effects/SnowComp.vue";
import ParticleComp from "@/components/effects/particle/ParticleComp.vue";
import FooterComp from "@/components/FooterComp.vue";

import pubsub from "@/utils/pubsub";
import { SCROLL_TOP } from "@/utils/subject";

export default {
  name: "HomeView",
  components: {
    NavComp,
    MyIntoductionComp,
    HotTagComp,
    SnowComp,
    ParticleComp,
    FooterComp,
  },
  data() {
    return {
      isShowBackTopBtn: false,
    };
  },
  mounted() {
    // 当页面滚动时显示或隐藏回到顶部按钮
    window.addEventListener("scroll", this.scroll);

    pubsub.$on(SCROLL_TOP, this.scrollTop);
  },
  methods: {
    scrollTop() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(this.scrollTop);
        window.scrollTo(0, currentScroll - currentScroll / 10);
      }
    },
    scroll() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.isShowBackTopBtn = true;
      } else {
        this.isShowBackTopBtn = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },
};
</script>

<style lang="scss" scoped>
.backToTopBtn {
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
}

// 清除默认样式: "margin-bottom: -0.75rem;"
.is-centered.columns {
  margin-bottom: unset;
}
</style>
