<template>
  <aside class="card">
    <div class="card-header">
      <p class="card-header-title">热门标签</p>
    </div>

    <div class="card-content">
      <b-tag
        class="centered-tag"
        v-for="item in list"
        :key="item.id"
        :type="randomType()"
        rounded
        @click="onClickTags(item)"
      >
        {{ item.name }}
      </b-tag>
    </div>
  </aside>
</template>

<script>
import { getHotTags } from "@/api/tags";
import pubsub from "@/utils/pubsub";
import { UPDATE_LIST } from "@/utils/subject";

export default {
  props: {},
  data() {
    return {
      list: [],
      tagType: [
        "is-black",
        "is-light",
        "is-dark",
        "is-primary",
        "is-info",
        "is-success",
        "is-warning",
        "is-danger",
      ],
    };
  },

  mounted() {
    getHotTags().then((res) => {
      if (res.code == 200) {
        this.list = res.data;
      }
    });
  },
  methods: {
    onClickTags(item) {
      if (this.$route.path === "/home") {
        pubsub.$emit(UPDATE_LIST, { tagsId: item.id });
      } else {
        this.$router.push({ name: "home", params: { tagsId: item.id } });
      }
    },
    randomType() {
      return this.tagType[Math.floor(Math.random() * this.tagType.length)];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: white;
  padding: 10px;
}

.tag-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.aside-title {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  & .title {
    font-weight: bold;
  }
}

.centered-tag {
  margin: 5px;
  cursor: pointer;
  &:hover {
    // box-shadow: inset 0 0 4px 2px white, 0 0 4px 2px yellow;
    box-shadow: inset 0 0 4px 2px white;
  }
}
</style>