<template>
  <div class="snow">
    <span
      class="snow-item"
      v-for="item in snowCount"
      :key="item"
      :ref="'snow-item-Ref_' + item"
    ></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snowCount: 50,
    };
  },
  mounted() {
    this.initEffect();
  },
  methods: {
    initEffect() {
      // 随机延时调用动画
      let loopFn = function (item) {
        // 最大雪块的倍数: 3
        const baseRatio = 3;
        // 随机比例, 用于根据比例数值设置雪块的大小
        const randomRatio = parseFloat((Math.random() * baseRatio).toFixed(2));

        item.style.width =
          item.customVariables.originSize.width * randomRatio + "px";
        item.style.height =
          item.customVariables.originSize.height * randomRatio + "px";

        // 获取重置后的item宽度
        const itemWidth = getComputedStyle(item).getPropertyValue("width");

        // 随机left值, 随机范围[0, 文档可视width - 当前item的实际width]
        const randomLeft =
          Math.random() *
          (document.documentElement.clientWidth - parseFloat(itemWidth));

        item.style.left = randomLeft + "px";

        // 每次执行动画时, 重置item的top值
        item.style.top = 0;

        // 延迟执行的范围[0, 10秒]
        var delay = Math.trunc(Math.random() * 1000 * 10);

        // 延时队列
        setTimeout(() => {
          item.blogCustomRun();
        }, delay);
      };

      for (let i = 1, l = this.snowCount; i < l; i++) {
        // 通过ref获取item元素
        let item = this.$refs["snow-item-Ref_" + i][0];
        if (!item.customVariables) {
          item.customVariables = {};
        }
        item.customVariables.originSize = {
          width: parseFloat(getComputedStyle(item).getPropertyValue("width")),
          height: parseFloat(getComputedStyle(item).getPropertyValue("height")),
        };

        // 给元素添加自定义方法
        item.blogCustomRun = function () {
          // this指向item本身

          // 每帧+1px, 帧率取决于硬件, requestAnimationFrame的特点
          this.style.top = parseFloat(this.style.top || 0) + 2 + "px";

          // 判断在可视范围区内则已知执行动画, 超出可视范围后, 重置并再次进入requestAnimationFrame循环
          if (
            parseFloat(this.style.top) < document.documentElement.clientHeight
          ) {
            requestAnimationFrame(this.blogCustomRun.bind(this));
          } else {
            loopFn(this);
          }
        };

        // 定义好运行函数后, 每个item立即执行动画
        loopFn(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.snow {
  width: 100%;
  top: -3.1em; // 这个值, 取决于放大雪块的倍数(JS逻辑中写了, 去找找), 目前是3倍并且会旋转, 所以3.1为了旋转式, 对角隐藏掉
  left: 0;
  position: fixed;

  & .snow-item {
    position: absolute;
    top: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 1em;
    height: 1em;
    text-align: center;
    background: url("~@/assets/snow.png") center center / cover no-repeat;
    animation: rotate 6s linear infinite;
  }
}
</style>