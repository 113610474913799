<template>
  <div class="particle">
    <div class="particle-boom" ref="particleBoomRef"></div>

    <div class="particle-follow" ref="particleFollowRef"></div>
  </div>
</template>

<script>
import ParticleManager from "./particleManager";

export default {
  data() {
    return {
      length: 50,
    };
  },
  mounted() {
    ParticleManager.getSingleInstance().initBoomParticle(
      this.$refs.particleBoomRef
    );

    window.addEventListener("mousemove", this.mouseMoveEvent);
    window.addEventListener("click", this.mouseClickEvent);
  },

  methods: {
    // 鼠标跟随效果
    mouseMoveEvent(event) {
      ParticleManager.getSingleInstance()
        .setFollowParent(this.$refs.particleFollowRef)
        .startFollow({
          x: event.x,
          y: event.y,
        });
    },

    // 点击效果
    mouseClickEvent(event) {
      ParticleManager.getSingleInstance().startBoom({
        x: event.x,
        y: event.y,
      });
    },
  },

  destroyed() {
    window.removeEventListener("mousemove", this.mouseMoveEvent);
    window.removeEventListener("click", this.mouseClickEvent);
  },
};
</script>