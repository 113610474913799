export default class ParticleFollow {
  #el;
  #parent;
  constructor() {
    this.#el = document.createElement("img");
    this.#el.setAttribute("src", require("@/assets/snow.png"));

    const width = Math.trunc(Math.random() * 10);
    this.#el.setAttribute(
      "style",
      `opacity: 1; 
      width: ${width}px;
      height: ${width}px;
      position: fixed;
      `
    );
  }

  /**实现该接口 */
  resetState() {
    // console.log("准备进池, resetState被调用...");

    // 进缓存池前, 恢复成不透明
    this.#el.style.opacity = 1;
  }

  set parent(element) {
    if (element instanceof Node === false) {
      throw new Error("parent must be Node type");
    }
    this.#parent = element;
    element.appendChild(this.#el);
  }

  get parent() {
    return this.#parent;
  }

  run(position) {

    if (!position) return;

    const floatValue = 30;
    // 基础位置上加正负30的随机值
    this.#el.style.top =
      Math.random() * (Math.random() * floatValue * 2 - floatValue) +
      position.y +
      "px";

    this.#el.style.left =
      Math.random() * (Math.random() * floatValue * 2 - floatValue) +
      position.x +
      "px";

    this.#el.style.opacity = this.#el.style.opacity - 0.01;

    if (this.#el.style.opacity != 0) {
      requestAnimationFrame(this.run.bind(this, position));
    } else {
      if (this.done) this.done();

      this.clear();
    }
  }

  clear() {
    this.#parent.removeChild(this.#el);
  }
}
