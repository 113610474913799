<template>
  <b-navbar class="block">
    <template #start>
      <b-navbar-item @click="onClickNav">首页</b-navbar-item>
      <b-navbar-item
        v-for="item in categoryList"
        :key="item.value"
        @click="onClickNav(item)"
      >
        {{ item.label }}
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import pubsub from "@/utils/pubsub";
import { getCategory } from "@/api/nav";
import { UPDATE_LIST } from "@/utils/subject";

export default {
  data() {
    return {
      categoryList: [],
    };
  },

  mounted() {
    getCategory().then((res) => {
      if (res.code == 200) {
        this.categoryList = res.data;
      }
    });
  },
  methods: {
    onClickNav(item = {}) {
      if (this.$route.path === "/home") {
        pubsub.$emit(UPDATE_LIST, { categoryId: item.value });
      } else {
        this.$router.push({ name: "home", params: { categoryId: item.value } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  width: $view-width;
  text-align: center;
  margin: $space auto;
}
nav li {
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  width: 60px;
}

ul {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px 10px;
}

nav li:hover {
  box-shadow: 0 0 5px #e1e1e1;
}

nav li:hover > .normal {
  display: block;
}

.normal {
  display: none;
}

.pre {
  position: relative;
}

.sub {
  position: absolute;
  top: 60%;
  left: 60%;
  width: 110px;
  z-index: 999;
}
</style>