import ObjectPool from "@/utils/objectPool";
import ParticleFollow from "./particleFollow";
import ParticleBoom from "./particleBoom";

export default class ParticleManager {
  // 静态成员

  static #singleInstance;

  static getSingleInstance() {
    if (!ParticleManager.#singleInstance) {
      ParticleManager.#singleInstance = new ParticleManager();
    }
    return ParticleManager.#singleInstance;
  }

  // 实例成员

  #followPool;
  #followParentElement;
  #boomParticles;

  // 初始化
  constructor() {
    this.#followPool = new ObjectPool(ParticleFollow);
    this.#boomParticles = [];

    window.test_pool = this.#followPool;
  }

  setFollowParent(followParentElement) {
    if (!followParentElement)
      throw new Error(`function 'setFollowParent' params is empty`);

    this.#followParentElement = followParentElement;
    return this;
  }

  startFollow(position) {
    const item = this.#followPool.getObject();

    item.parent = this.#followParentElement;

    item.run(position);

    item.done = () => {
      // 动画结束后, 执行done钩子, item回到对象池
      this.#followPool.toPool(item);
    };
  }

  initBoomParticle(boomParentElement, length = 30) {
    if (!boomParentElement)
      throw new Error(`function 'setBoomParent' params is empty`);

    for (let i = 0; i < length; i++) {
      const item = new ParticleBoom();
      item.parent = boomParentElement;
      this.#boomParticles.push(item);
    }

    return this;
  }
  
  startBoom(position) {
    for (let i = 0, l = this.#boomParticles.length; i < l; i++) {
      const item = this.#boomParticles[i];
      item.run(position);
    }
  }
}
