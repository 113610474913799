import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// 
// import "@/directives";

import "reset-css";
import "normalize.css";
import "@/assets/scss/main.scss";
import "./plugins/buefy.js"

Vue.config.productionTip = false;

window._vue = new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
