export default class ObjectPool {
  #list;
  #objeClass;
  list;

  constructor(cls) {
    this.#list = [];
    this.#objeClass = cls;
    this.list = this.#list;
  }

  get length() {
    return this.#list.length;
  }

  /**出池 */
  getObject() {
    if (this.#list.length == 0) {
      return new this.#objeClass();
    }
    return this.#list.pop();
  }

  /**进池 */
  toPool(obj) {
    try {
      obj.resetState();
    } catch (error) {
      console.error(
        `对象: ${Object.prototype.toString.call(
          obj
        )}, 没有实现接口 resetState 方法`
      );
    }
    this.#list.push(obj);
  }
}
